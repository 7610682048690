<template>
    <section class="encuesta-crear2 mt-4 ml-4">
        <!-- breadcrumb -->
        <div class="row">
            <div class="col-auto my-auto">
                <el-breadcrumb separator-class="el-icon-arrow-right f-15">
                    <el-breadcrumb-item :to="{ name: 'encuestas.main' }" >Encuestas</el-breadcrumb-item >
                    <el-breadcrumb-item>Editar encuesta</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="col-2 ml-auto">
                <div class="d-flex">
                    <button class="btn-general btn btn-sm f-14" @click="enviarEncuesta">Enviar</button>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="tooltip-editar-residente">
                            <div class="d-flex editar-opcion py-1 px-1 cr-pointer br-4" @click="modalEliminarEncuesta">
                                <p class="f-15">Eliminar</p>
                            </div>
                        </div>
                        <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-3">
                            <i class="icon-vertical-points" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row">
            <p class="f-20 f-500 mb-4 mt-5 col-12">Editar encuesta</p>
            <el-tabs type="card" class="tabs-crear-encuesta w-100">
                <el-tab-pane label="Información">
                    <div class="row mx-0">
                        <ValidationObserver tag="div" class="col-12 col-sm-9 col-md-6 col-lg-6 col-xl-4" ref="form">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:120" name="nombre">
                                        <p class="f-12 pl-2 label-inputs">Título de la encuesta</p>
                                        <el-input placeholder="Nombre" v-model="encuesta.nombre" show-word-limit maxlength="120" size="small" @change="guardarBasico"></el-input>
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:750" name="descripción">
                                        <p class="f-12 pl-2 label-inputs">Descripción</p>
                                        <el-input placeholder="Descripción de la encuesta" v-model="encuesta.descripcion" show-word-limit maxlength="750" type="textarea"  :autosize="{ minRows: 4, maxRows: 4}"  @change="guardarBasico"/>
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-12 d-flex">
                                    <el-checkbox v-model="todosResidentes" :true-label="1" :false-label="0"/>
                                    <span class="ml-2">Todos los residentes pueden contestar</span>
                                </div>
                            </div>
                            <div v-if="!todosResidentes" class="row mb-4">
                                <div class="col-12">
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="numeric|max_value:255" name="cantidad permitida">
                                        <p class="f-12 pl-2 label-inputs">Máximos de encuestados por vivienda</p>
                                        <el-input placeholder="Escribe el valor" v-model="encuesta.cantidad_permitida" size="small" @change="guardarBasico"></el-input>
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                        <div class="col-12 col-sm-9 col-md-6 col-lg-6 col-xl-4">
                            <div class="row mb-1">
                                <div class="col-12 d-flex">
                                    <el-checkbox v-model="encuesta.solo_grupo" :true-label="0" :false-label="1" @change="handleSoloGrupo"/>
                                    <span class="ml-2">Todas las viviendas pueden contestar</span>
                                </div>
                            </div>
                            <div v-if="encuesta.solo_grupo" class="row mx-2">
                                    <p class="f-12 pl-2 label-inputs">Destinatarios</p>
                                    <select-grupos @setAgrupacion="setAgrupacion"></select-grupos>
                            </div>
                            <!-- ciclo de los destinatarios solo_grupo = 1 -->
                            <div v-if="encuesta.solo_grupo" class="row mb-4 overflow-auto custom-scroll" style="height:calc(100vh - 420px);">
                                <div class="col-12 text-86">
                                    <p v-for="(destinatario,i) in encuesta.destinatarios" :key="i" class="mb-2 br-20 bg-f5 px-2 py-1 border d-flex justify-content-between">{{destinatario.nombre}}
                                        <i v-show="encuesta.solo_grupo && encuesta.destinatarios.length > 1" class="icon-close cr-pointer f-12 my-auto" @click="eliminarGrupo(destinatario.id_vivienda)" /> 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label" class="d-middle-center" > 
                        Preguntas <div class="f-11 n-preguntas d-middle-center ml-2">{{total_preguntas}}</div> 
                    </span>

                    <preguntas ref="preguntas" @total_preguntas="setTotalPreguntas" />
                    
                </el-tab-pane>
            </el-tabs>
        </div>
        
        <!-- partials -->
        <modal-eliminar ref="abrirEliminarEncuesta" titulo="Eliminar encuesta" mensaje="¿Desea eliminar esta encuesta?" tamano="modal-sm" @eliminar="eliminarEncuesta"/>
        
        <modal-enviar-encuesta ref="abrirModalEnviarEncuesta" @guardar="guardar"/>
    </section> 
</template>
<script>
import Encuestas from '~/services/encuestas'
export default {
    components:{
        modalEnviarEncuesta: () => import('./partials/modalEnviarEncuesta'),
        preguntas: () => import('./componentes/preguntas')
    },
    data(){
        return{
            todosResidentes: 0,
            activeName: 'first',
            inputTituloEncuesta:'',
            encuesta:{
                id: null,
                id_encuesta: null,
                cantidad_permitida: null,
            },
            total_preguntas: 0,
            preguntasNoValidas: []
        }
    },
    watch: {
        todosResidentes(){
            if(this.todosResidentes)this.encuesta.cantidad_permitida = null;else this.encuesta.cantidad_permitida = 3
            this.save()
        }
    },
    created(){
      this.obtenerEncuesta()
    },
    methods:{
        async save(){
            try {
                await Encuestas.guardar(this.encuesta)
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async handleSoloGrupo(value){
            await this.save()
            if(value == 0) this.encuesta.destinatarios = []
        },
        modalEliminarEncuesta(){
            this.$refs.abrirEliminarEncuesta.toggle()
        },
        enviarEncuesta(){
            this.$refs.abrirModalEnviarEncuesta.toggle()
        },
        setAgrupacion(idGrupo){
            this.adicionarDestinatario(idGrupo)
        },
        async adicionarDestinatario(idGrupo){
            try {
                let params = {
                    id_encuesta: this.encuesta.id,
                    id_vivienda: idGrupo
                }
                const { data } = await Encuestas.adicionarDestinatario(params)
                this.notificacion('','Vivienda añadida correctamente','success')

                this.obtenerEncuesta()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async obtenerEncuesta(){
          try {
            this.loading = true
            const {data} = await Encuestas.mostrar(this.$route.params.id_encuesta)
            this.encuesta = data.data
            this.encuesta.id_encuesta = data.data.id
            this.loading = false
            this.todosResidentes = Number(!this.encuesta.cantidad_permitida)
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }
        },
        async guardarBasico(){
            try {
                let validate = this.$refs.form.validate()
                if(!validate) return this.notificacion('','Campos incompletos','warning')

                const { data } = await Encuestas.guardar(this.encuesta)
                this.notificacion('','Encuesta actualizada correctamente','success')  
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async guardar(vigencia){
          try {
            this.loading = true
            this.encuesta.fecha_inicio = vigencia.inicio
            this.encuesta.fecha_fin = vigencia.fin
            let payload = this.encuesta
            payload.preguntas = this.$refs.preguntas.getPreguntas()

            // se validan que las preguntas creadas sean validas en su totalidad
            if(!payload.preguntas.length) return this.notificacion('','Por favor crea al menos una pregunta')

            this.preguntasNoValidas = []
            payload.preguntas.forEach((el,index)=>this.validarCamposTodasPreguntas(el,index))
            if(this.preguntasNoValidas.length) {
                this.$refs.preguntas.mostrarPreguntasNoValidas(this.preguntasNoValidas)
                return this.notificacion('','Por favor revisa que todos los campos de las preguntas sean validos')
            }
            // se reordenan todos los objetos con su respectiva posicion; las preguntas y las respuestas de cada una de estas
            payload.preguntas.map((el,index)=>{
                el.posicion = index + 1
                // si la pregunta es tipo 2 o 3
                if(el.tipo != 1){
                    el.opciones.map((el,index)=>{el.posicion = index + 1; return el})
                }
                return el
            })

            const {data} = await Encuestas.guardarFull(payload)
            this.$refs.abrirModalEnviarEncuesta.reset()
            this.$refs.abrirModalEnviarEncuesta.toggle()

            this.notificacion('','Encuesta guardada correctamente','success')
            this.$router.replace({name: "encuestas.main"})
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }
        },

        setTotalPreguntas(total){
          this.total_preguntas = total
        },
        validarCamposTodasPreguntas(pregunta,index){
            // se valida si la pregunta no tiene título
            if(!pregunta.titulo) {
                return this.preguntasNoValidas.push(index)
                
            }
            // si es tipo != 2 y no tiene opciones
            else if((typeof pregunta.opciones == 'undefined' || !pregunta.opciones.length ) && pregunta.tipo != 1){
                return this.preguntasNoValidas.push(index)
                 
            }
            // si es tipo != 1 y tiene titulo de respuestas null
            else if(typeof pregunta.opciones != 'undefined' && pregunta.tipo != 1){
                const textoEsNull = (el) => !el.texto
                let respuestasNoValidas = pregunta.opciones.some(el=>textoEsNull(el))

                if(respuestasNoValidas){
                    return this.preguntasNoValidas.push(index)
                     
                } 
            }

            else return true
        },
        async eliminarGrupo(idGrupo){
            try {
                let params = {
                    id_vivienda: idGrupo,
                    id_encuesta: this.encuesta.id
                }
                const { data } = await Encuestas.eliminarDestinatario(params)

                this.notificacion('','Grupo eliminado correctamente','success')
                this.obtenerEncuesta()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarEncuesta(){
            try {
                let id_encuesta = this.encuesta.id
                const { data } = await Encuestas.eliminarEncuesta({id_encuesta})

                this.$refs.abrirEliminarEncuesta.toggle();
                this.$router.replace({name: "encuestas.main"})
                this.notificacion('','Encuesta eliminada correctamente','success')
            } catch (error) {
                this.errorCatch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.encuesta-crear2{
    .btn-general{
        min-width: 86px;
    }
    .n-preguntas{
        background: #000;
        color: #fff;
        width: 17px;
        min-width: 17px;
        height: 17px;
        border-radius: 50%;
    }
    .card-pregunta-encuesta{
        border-radius: 12px;
        border: 1px solid #DBDBDB;
        .n-pregunta{
            border-radius: 12px;
            background: #000;
            color: #fff;
            max-width: 34px;
            min-width: 34px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
        }
        .set-draggable{
            position: absolute;
            top: 0px;
            right: 50%;
            i:first-child{
                position: relative;
                left: 14px;
            }
        }
        .text-verde{
            color: #109881;
        }
    }
}
</style>